#navbar {
    position:absolute;
    width: 90%;
    top:5%;
    z-index: 2;
  }
/*
  #changeLang {
    div:focus {
      outline: none;
    }
    * {
      outline: none;
    }
    div {
      border: none;
    }
  }*/